import { Injectable } from "@angular/core";
import { GoogleTagManagerService } from "angular-google-tag-manager";
import { LoggedInUser } from "../../auth/store";
import { Store } from "@ngxs/store";

@Injectable()
export class AnalyticsService {
  constructor(
    private gtmService: GoogleTagManagerService,
    private store: Store
  ) {
  }

  public pushRouteChangeEvent() {
    const user = this.store.selectSnapshot<LoggedInUser>((state) => state.loggedInUser?.user);
    const userId = user?._id;
    const userRole = user?.role?.toString();
    const eduProfile = user?.eduProfile;
    const grade = user?.grade;
    console.log("Pushing user data to data layer: " + userId + " with role: " + userRole);

    this.gtmService.pushTag({
      userId: userId,
      role: userRole,
      district: user?.group?.name,
      state: user?.group?.address?.state,
      educationLevel: eduProfile?.educationLevel,
      gradesTaught: eduProfile?.gradesTaught || [],
      subjectsTaught: eduProfile?.subjectsTaught || [],
      grade: grade || []
    });
  }
}
