export const environment = {
  name: 'Production',
  production: true,
  API_URL: 'https://api.plasma.games/graphql',
  SCIOPS_API: 'https://api.plasma.games/',
  plugins: [],
  SSO_URL_GOOGLE: 'https://api.plasma.games/auth/google/login',
  SSO_URL_CLEVER: 'https://clever.com/oauth/authorize?response_type=code&client_id=2c3467004487e0a56a73&redirect_uri=https://api.plasma.games/auth/clever/login',
  SSO_CLEVER_ENABLED: true,
  GOOGLE_TAG_ID: 'GTM-KS2FQZB7',
  AMPLITUDE_ID: 'f7423da74f9759c4d391d8b868a82c94',
  AMPLITUDE_URL: 'https://api.plasma.games/amplitude',
};
