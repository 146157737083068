import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { CopyToClipboard, CopyToClipboardOptions } from '@common/models';
import AOS, { Aos } from 'aos';
import lity, { Lity } from 'lity';
import { Observable, map } from 'rxjs';
import { ClipboardService } from './clipboard.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Injectable()
export class UtilityService {
  _window: Window;
  parse: JSON['parse'];
  stringify: JSON['stringify'];
  localStorage: Storage;
  _copyToClipboard: CopyToClipboard;
  _AOS: Aos;
  _lity: Lity;

  clipboard = inject(ClipboardService);

  constructor(
    private http: HttpClient,
    private breakpointObserver: BreakpointObserver
  ) {
    this._window = window;
    this.parse = JSON.parse;
    this.stringify = JSON.stringify;
    this.localStorage = localStorage;
    // this._copyToClipboard = copyToClipboard;
    this._AOS = AOS;
    this._lity = lity;
  }

  get window(): Window {
    return this._window;
  }
  get version$(): Observable<string> {
    return this.http.get('/assets/version', { responseType: 'text' });
  }
  get AOS(): Aos {
    return this._AOS;
  }

  getStoredObject<T>(objectName: string): T | undefined {
    const objectString = this.localStorage.getItem(objectName);
    if (!objectString) {
      return undefined;
    }
    return this.parse(objectString) as T;
  }

  storeObject(objectName: string, objectToStore: {}): void {
    this.localStorage.setItem(objectName, this.stringify(objectToStore));
  }

  copyToClipboard(text: string, options?: CopyToClipboardOptions) {
    this.clipboard.copy(text, options);
  }

  getMediaQuery(): Observable<string> {
    return this.breakpointObserver.observe(Object.values(Breakpoints)).pipe(
      map((result) => {
        for (const key in result.breakpoints) {
          if (result.breakpoints[key]) {
            return Object.keys(Breakpoints).find((value) => (Breakpoints as any)[value] === key)!;
          }
        }
        return 'unknown';
      })
    );
  }

  getModalResizeWidth(desiredSize: string, defaultVal: number): Observable<number> {
    return this.getMediaQuery().pipe(
      map((mediaQuery) => {
        console.error('mediaQuery', mediaQuery);
        return mediaQuery === desiredSize ? window.innerWidth : defaultVal;
      })
    );
  }
}
